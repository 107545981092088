import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Dialog, DialogTitle, DialogContent, Button, CircularProgress } from "@mui/material";
import { Box, Stack, Typography } from "@mui/material";
import {
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ChevronLeft as LeftArrowIcon,
  ChevronRight as RightArrowIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import moment from "moment";
import AuthorizedPDF from "component/AuthorizedPDF";
import AuthorizedImage from "component/AuthorizedImage";
import AuthorizedVideo from "component/AuthorizedVideo";
import api from "app/api";

const ViewImageVideoModal = ({ showDialogView = false, handleCloseDialogView, data = [], selectedFileIndex = 0 }) => {
  const { appConstants } = useSelector((state) => state.service);
  const [filePath, setFilePath] = useState(null);
  const [currentFilePathIndex, setCurrentFilePathIndex] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(0.8);
  const [imageZoomLevel, setImageZoomLevel] = useState(1);
  // const [videoLoadingProgress, setVideoLoadingProgress] = useState(null);
  const [videoUrl, setVideo] = useState(null);

  const [list, setList] = useState([]);
  useEffect(() => {
    if (data) {
      setList(data);
      setFilePath(data[selectedFileIndex]);
      setCurrentFilePathIndex(selectedFileIndex);
    }
  }, [data, selectedFileIndex]);

  const handleZoomIn = () => {
    if (filePath && filePath.type === appConstants.allTypes.TYPE_OF_CONTENT.PDF) {
      setZoomLevel((prevZoomLevel) => prevZoomLevel + 0.1);
    } else if (filePath && filePath.type === appConstants.allTypes.TYPE_OF_CONTENT.IMAGE) {
      setImageZoomLevel((prevZoomLevel) => prevZoomLevel + 0.1);
    } else if (filePath && !filePath?.type) {
      setImageZoomLevel((prevZoomLevel) => prevZoomLevel + 0.1);
    }
  };

  const handleZoomOut = () => {
    if (filePath && filePath.type === appConstants.allTypes.TYPE_OF_CONTENT.PDF) {
      setZoomLevel((prevZoomLevel) => prevZoomLevel - 0.1);
    } else if (filePath && filePath.type === appConstants.allTypes.TYPE_OF_CONTENT.IMAGE) {
      setImageZoomLevel((prevZoomLevel) => prevZoomLevel - 0.1);
    } else if (filePath && !filePath?.type) {
      setImageZoomLevel((prevZoomLevel) => prevZoomLevel - 0.1);
    }
  };

  const handleNext = () => {
    if (currentFilePathIndex === list.length - 1) {
      setFilePath(list[0]);
      setCurrentFilePathIndex(0);
    } else {
      setFilePath(list[currentFilePathIndex + 1]);
      setCurrentFilePathIndex(currentFilePathIndex + 1);
    }
  };

  const handlePrevious = () => {
    if (currentFilePathIndex === 0) {
      setFilePath(list[list.length - 1]);
      setCurrentFilePathIndex(list.length - 1);
    } else {
      setFilePath(list[currentFilePathIndex - 1]);
      setCurrentFilePathIndex(currentFilePathIndex - 1);
    }
  };

  const handleDownload = async () => {
    try {
      let fileUrl = videoUrl;
      if (!fileUrl) {
        const response = await api.get(`/serviceapi/writeImageToClient?location=${filePath?.message || filePath}`, {
          responseType: "blob",
        });
        fileUrl = URL.createObjectURL(response.data);
      }
      const link = document.createElement("a");
      link.href = fileUrl;
      const path = filePath?.message || filePath;
      const fileName = path.split("/").pop();
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(fileUrl);
      setVideo(null);
    } catch (error) {
      setVideo(null);
      console.log(error);
    }
  };

  return (
    <div>
      <Dialog
        open={showDialogView}
        onClose={handleCloseDialogView}
        PaperProps={{
          style: {
            maxWidth: "1100px",
            minWidth: "500px",
          },
        }}
      >
        <DialogTitle style={{ flexGrow: 1 }} textAlign={"center"}>
          <Stack direction={"row"} sx={{ flex: 1 }} alignItems={"center"}>
            <div style={{ flex: 0.85 }}>
              <Typography ml={5}>
                {filePath?.postedDate
                  ? moment(filePath?.postedDate).format("DD MMM YYYY HH:mm")
                  : filePath?.dateCreated
                  ? moment(filePath?.dateCreated).format("DD MMM YYYY HH:mm")
                  : ""}
              </Typography>
            </div>
            <div style={{ flex: 0.15 }}>
              <Button
                startIcon={<CloseIcon />}
                onClick={() => {
                  setFilePath(null);
                  setList([]);
                  setCurrentFilePathIndex(0);
                  handleCloseDialogView();
                  setVideo(null);
                }}
                sx={{ ml: 2 }}
              >
                Close
              </Button>
            </div>
          </Stack>
        </DialogTitle>
        <DialogContent>
          {filePath && filePath.type == appConstants?.allTypes?.TYPE_OF_CONTENT.PDF ? (
            <AuthorizedPDF path={filePath.message} scale={zoomLevel} showPages={true} />
          ) : filePath && filePath.type == appConstants?.allTypes?.TYPE_OF_CONTENT.IMAGE ? (
            <AuthorizedImage path={filePath.message} zoomLevel={imageZoomLevel} />
          ) : filePath && filePath.type == appConstants?.allTypes?.TYPE_OF_CONTENT.VIDEO ? (
            <Box>
              <AuthorizedVideo path={filePath.message} setVideo={setVideo} size={filePath?.fileSize || ""} />
              {/* {videoLoadingProgress && videoLoadingProgress != 100 && (
                  <Box sx={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress variant="determinate" value={videoLoadingProgress} color="secondary" size={50} />
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}
                    >{`${videoLoadingProgress}%`}</Typography>
                  </Box>
                )} */}
            </Box>
          ) : filePath ? (
            <AuthorizedImage path={filePath} zoomLevel={imageZoomLevel} />
          ) : null}
        </DialogContent>
        <Stack direction="row" alignItems={"center"} justifyContent="center" sx={{ mt: 4, mb: 2, height: "100%" }}>
          <Button onClick={handlePrevious} sx={{ height: "self", mr: 7 }}>
            <LeftArrowIcon />
            Prev
          </Button>
          {filePath && filePath.type == appConstants?.allTypes?.TYPE_OF_CONTENT.VIDEO ? null : (
            <>
              <Button onClick={handleZoomOut}>
                <ZoomOutIcon style={{ fontSize: 25 }} />
              </Button>
              <Button onClick={handleZoomIn}>
                <ZoomInIcon style={{ fontSize: 25 }} />
              </Button>
            </>
          )}

          <Button onClick={handleDownload} sx={{ height: "self", mr: 7 }}>
            Download
          </Button>
          <Button onClick={handleNext}>
            Next
            <RightArrowIcon />
          </Button>
        </Stack>
      </Dialog>
    </div>
  );
};

export default ViewImageVideoModal;
