import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, Stack, List, Tooltip, Typography, CardContent } from "@mui/material";
import moment from "moment";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, useTheme, LinearProgress } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ZoomIn as ZoomInIcon, ZoomOut as ZoomOutIcon } from "@mui/icons-material";

import { addImageOrVideoToServiceTicket, readAsyncStorageValues, readServiceTicketMessages } from "features/service/service.slice";
import { selectCurrentToken } from "features/auth/auth.slice";
import AuthorizedImage from "component/AuthorizedImage";
import AuthorizedPDF from "component/AuthorizedPDF";
import api from "app/api";
import AuthorizedVideo from "component/AuthorizedVideo";
import videoImage from "../../images/Video.png";
import CircularProgress, { CircularProgressProps } from "@mui/material/CircularProgress";
import DrawingComponent from "component/DrawingComponent";
import ViewImageVideoModal from "component/ViewImageVideoModal";
import UploadProgressModal from "component/UploadProgressModal";

const Gallery = ({ ticketId }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { serviceTicketMessages, appConstants, isLoading, serviceTicketById } = useSelector((state) => state.service);
  let token = useSelector(selectCurrentToken);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const fileInputRef = useRef(null);
  const [showDialogView, setShowDialogView] = useState(false);
  token = token.access_token ? token.access_token : token;
  const [currentFilePathIndex, setCurrentFilePathIndex] = useState(null);
  const [isDrawingComponentOpen, setDrawingComponentOpen] = useState(false);
  const [serviceTicketMessagesList, setServiceTicketMessagesList] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({ progress: 0, rate: 0, total: 0, buffer: 10 });
  const [showUploadDialog, setShowUploadDialog] = useState(false);

  useEffect(() => {
    dispatch(readServiceTicketMessages(ticketId));
  }, [dispatch, ticketId]);

  useEffect(() => {
    dispatch(readAsyncStorageValues());
  }, [dispatch]);

  useEffect(() => {
    if (appConstants && appConstants?.allTypes && serviceTicketMessages) {
      const imageVideoPdfList = serviceTicketMessages.filter(
        (message) =>
          message.type === appConstants?.allTypes?.TYPE_OF_CONTENT.IMAGE ||
          message.type === appConstants?.allTypes?.TYPE_OF_CONTENT.VIDEO ||
          message.type === appConstants?.allTypes?.TYPE_OF_CONTENT.PDF
      );
      setServiceTicketMessagesList(imageVideoPdfList || []);
    }
  }, [appConstants, serviceTicketMessages]);

  const handleFileSelect = (event) => {
    if (event.target.files[0].type.includes("image")) {
      setDrawingComponentOpen(true);
    } else setShowDialog(true);
    setSelectedFile(event.target.files[0]);
  };

  const handleViewFile = (file, index) => {
    setCurrentFilePathIndex(index);
    setShowDialogView(true);
  };

  const progressCallback = (progressEvent) => {
    const percentRateFraction = progressEvent.rate / 1048576;
    const percentTotalFraction = progressEvent.total / 1048576;
    const percentLoadedFraction = progressEvent.loaded / 1048576;
    const percentFraction = percentLoadedFraction / percentTotalFraction;
    const roundedNum = Math.round(percentLoadedFraction * 100) / 100;
    const roundedNumRate = Math.round(percentRateFraction * 100) / 100;
    const roundedNumTotal = Math.round(percentTotalFraction * 100) / 100;
    const diff = Math.random() * 10;
    setUploadProgress({ progress: roundedNum, rate: roundedNumRate, total: roundedNumTotal, buffer: percentFraction * 100 + diff + 5 });
  };

  const handleFileUpload = async (editedImage = selectedFile) => {
    setShowUploadDialog(true);
    const formData = new FormData();
    formData.append("image", editedImage);
    editedImage.type == "application/pdf"
      ? formData.append("type", "pdf")
      : editedImage.type.includes("video")
      ? formData.append("type", "video/mp4")
      : formData.append("type", "image/jpg");
    formData.append("serviceTicketId", "" + ticketId);
    setShowDialog(false);
    try {
      dispatch(addImageOrVideoToServiceTicket({ formData, progressCallback: progressCallback })).then((res) => {
        toast.success("File uploaded successfully!", {
          autoClose: 1000,
        });
        setUploadProgress({ progress: 0, rate: 0, total: 0, buffer: 10 });
        dispatch(readServiceTicketMessages(ticketId));
        setSelectedFile(null);
        setShowUploadDialog(false);
      });
    } catch (error) {
      toast.error("Please try after some time!");
    }
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    setSelectedFile(null);
    fileInputRef.current.value = null;
  };

  const handleCloseDialogView = () => {
    setCurrentFilePathIndex(null);
    setShowDialogView(false);
    fileInputRef.current.value = null;
  };

  const formatFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1000;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return Math.floor(bytes / Math.pow(k, i)) + " " + sizes[i];
  };

  if (isLoading)
    return (
      <div
        style={{
          textAlign: "center",
          paddingTop: 50,
          paddingBottom: 50,
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  return (
    <div>
      {
        <ViewImageVideoModal
          showDialogView={showDialogView}
          handleCloseDialogView={handleCloseDialogView}
          data={serviceTicketMessagesList}
          selectedFileIndex={currentFilePathIndex}
        />
      }
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
        <Typography variant="h5" fontWeight={600} mb={1}>
          Files
        </Typography>
        <input type="file" style={{ display: "none" }} onChange={handleFileSelect} id="fileInput" ref={fileInputRef} />
        {serviceTicketById?.status != appConstants?.allTypes?.SERVICE_TICKET_STATUS.SUPERVISOR_APPROVED ? (
          <Tooltip title="Add file">
            <Button
              sx={{ height: "self" }}
              variant="contained"
              color="primary"
              onClick={() => document.getElementById("fileInput").click()}
            >
              Add
            </Button>
          </Tooltip>
        ) : null}
      </Stack>
      <div>
        <UploadProgressModal
          selectedFiles={selectedFile ? [selectedFile] : []}
          showUploadDialog={showUploadDialog}
          uploadProgress={uploadProgress}
        />
      </div>

      {selectedFile && selectedFile?.type.includes("image") && isDrawingComponentOpen ? (
        <DrawingComponent
          isDrawingComponentOpen={isDrawingComponentOpen}
          closeDrawingComponent={() => {
            setSelectedFile(null);
            setDrawingComponentOpen(false);
          }}
          onClickDoneEditing={handleFileUpload}
          selectedImageUri={selectedFile}
          buttonText={"Upload"}
        />
      ) : (
        <Dialog open={showDialog} onClose={handleCloseDialog}>
          <DialogTitle>
            <Typography mr={1}>Selected File </Typography>
          </DialogTitle>
          <DialogContent>
            {/* File preview */}
            {selectedFile ? (
              selectedFile.type.includes("image") ? (
                <img src={URL.createObjectURL(selectedFile)} alt="Selected File" style={{ maxWidth: "100%", maxHeight: "500px" }} />
              ) : selectedFile.type.includes("pdf") ? (
                <embed src={URL.createObjectURL(selectedFile)} width="800" height="700" />
              ) : selectedFile.type.includes("video") ? (
                <video controls style={{ width: "100%" }} height="auto">
                  <source src={URL.createObjectURL(selectedFile)} type={selectedFile.type} />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <span>Selected File is Incorrect</span>
              )
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button disabled={uploadProgress?.progress ? true : false} onClick={handleCloseDialog}>
              Cancel
            </Button>
            {selectedFile ? (
              <Button variant="contained" disabled={uploadProgress?.progress ? true : false} onClick={() => handleFileUpload()}>
                Upload
              </Button>
            ) : null}
          </DialogActions>
        </Dialog>
      )}
      <ToastContainer />
      <Stack mb={1}>
        <List
          sx={{
            maxHeight: 500,
            overflow: "auto",
          }}
        >
          {Object.keys(appConstants).length &&
            serviceTicketMessagesList.map((note, index) => (
              <div key={index}>
                {note.type === appConstants.allTypes.TYPE_OF_CONTENT.IMAGE ? (
                  <Card
                    sx={{
                      mb: 2,
                      mt: 2,
                      p: 1,
                      mr: 2,
                      width: "100%",
                      display: "flex",
                    }}
                    onClick={() => handleViewFile(note, index)}
                  >
                    <AuthorizedImage path={note.message} width={100} height={100} />
                    <Box>
                      <CardContent>
                        <Typography gutterBottom variant="body2" component="div" color="text.secondary">
                          {note?.postedDate || note?.dateCreated
                            ? moment(note?.postedDate ? note.postedDate : note.dateCreated).format("DD MMM HH:mm")
                            : "N.A"}
                        </Typography>
                        <Typography variant="body2">{note.postedUser.name}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          {note?.fileSize ? formatFileSize(note.fileSize) : null}
                        </Typography>
                      </CardContent>
                    </Box>
                  </Card>
                ) : note.type === appConstants.allTypes.TYPE_OF_CONTENT.PDF ? (
                  <Card
                    sx={{
                      mb: 2,
                      mt: 2,
                      p: 1,
                      mr: 2,
                      width: "100%",
                      background: "rgb(247,247,247)",
                      display: "flex",
                      background: "linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(255,255,255,1) 92%, rgba(247,247,247,1) 100%)",
                    }}
                    onClick={() => handleViewFile(note, index)}
                  >
                    <div style={{ width: 100, height: 100 }}>
                      <AuthorizedPDF path={note.message} scale={0.135} />
                    </div>
                    <Box>
                      <CardContent>
                        <Typography gutterBottom variant="body2" component="div" color="text.secondary">
                          {note?.postedDate || note?.dateCreated
                            ? moment(note?.postedDate ? note.postedDate : note.dateCreated).format("DD MMM HH:mm")
                            : "N.A"}
                        </Typography>
                        <Typography variant="body2">{note.postedUser.name}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          {note?.fileSize ? formatFileSize(note.fileSize) : null}
                        </Typography>
                      </CardContent>
                    </Box>
                  </Card>
                ) : note.type === "video/mp4" ? (
                  <Card
                    sx={{
                      mb: 2,
                      mt: 2,
                      p: 1,
                      mr: 2,
                      width: "100%",
                      background: "rgb(247,247,247)",
                      display: "flex",
                      background: "linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(255,255,255,1) 92%, rgba(247,247,247,1) 100%)",
                    }}
                    onClick={() => handleViewFile(note, index)}
                  >
                    <div style={{ width: 100, height: 100 }}>
                      <img src={videoImage} width={100} height={100} />
                    </div>
                    <Box>
                      <CardContent>
                        <Typography gutterBottom variant="body2" component="div" color="text.secondary">
                          {note?.postedDate || note?.dateCreated
                            ? moment(note?.postedDate ? note.postedDate : note.dateCreated).format("DD MMM HH:mm")
                            : "N.A"}
                        </Typography>
                        <Typography variant="body2">{note.postedUser.name}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          {note?.fileSize ? formatFileSize(note.fileSize) : null}
                        </Typography>
                      </CardContent>
                    </Box>
                  </Card>
                ) : null}
              </div>
            ))}
        </List>
      </Stack>
    </div>
  );
};

export default Gallery;
